import { rgbToHsl } from '../../utils/css';

export class ColorFormatter {
  constructor(
    public readonly rgba_255: Readonly<[number, number, number, number]>,
    public readonly hsla_0_1 = [
      ...rgbToHsl(rgba_255[0], rgba_255[1], rgba_255[2]),
      rgba_255[3] / 255,
    ]
  ) {}

  cssRGBA(a: number = this.rgba_255[3] / 255) {
    const parts = [
      this.rgba_255[0],
      this.rgba_255[1],
      this.rgba_255[2],
      a,
    ].join(',');

    return `rgba(${parts})`;
  }

  cssHSLA(a: number = this.hsla_0_1[3]) {
    const parts = [
      this.hsla_0_1[0] * 360,
      `${this.hsla_0_1[1] * 100}%`,
      `${this.hsla_0_1[2] * 100}%`,
      a,
    ].join(',');

    return `hsla(${parts})`;
  }

  cssHSLAWithLightness(l_0_1: number) {
    const parts = [
      this.hsla_0_1[0] * 360,
      `${this.hsla_0_1[1] * 100}%`,
      `${Math.max(l_0_1 * 100, 0)}%`,
      `${this.hsla_0_1[3]}`,
    ].join(',');

    return `hsla(${parts})`;
  }

  cssHex(): string {
    const r = this.rgba_255[0].toString(16).padStart(2, '0');
    const g = this.rgba_255[1].toString(16).padStart(2, '0');
    const b = this.rgba_255[2].toString(16).padStart(2, '0');
    const a = Math.round(this.rgba_255[3]).toString(16).padStart(2, '0');

    if (this.rgba_255[3] === 255) {
      return `#${r}${g}${b}`;
    }
    return `#${r}${g}${b}${a}`;
  }
}
